@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
body {
    font-family: 'Poppins', sans-serif !important;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif !important;
    /* font-size: 1.1em; */
    font-weight: 300;
    /* line-height: 1.7em; */
    color: #999;
}

div {
    font-family: 'Poppins', sans-serif !important;
}

a {
    color: #8A2966;
    transition: all 0.3s;
    text-decoration: none;
}

a:focus , a:hover{
    cursor: pointer;
    text-decoration: none;
    color: #8A2966;
}

.form-control:focus {
  border-color: #8A2966 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}


/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: rgb(233,74,69);
    background: linear-gradient(rgba(233,74,69,1) 0%, rgba(138,41,102,1) 75%); 
    color: #fff;
    transition: all 0.3s;
    position: fixed;
    z-index: 1;
    height: 100vh;
    overflow-x: hidden;
}


#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding-top: 25px;
    text-align: center;
    /* border-bottom: 1px solid #fff; */
    /* background: #6d7fcc; */
}

#sidebar ul.components {
    padding: 20px 0;
    /* border-bottom: 1px solid #47748b; */
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    color: #fff;
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #E94A45;
    background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #8A2966 !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    margin-left: 250px;
}

#top-header {
    display: none;
}



/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
    #content {
        margin-left: 0px;
    }

    #top-header {
        display: block;
    }
}
